import React from 'react';
import './GeneratorPage.css'; // Import your custom CSS for styling

const GeneratorPage: React.FC = () => {
  return (
    <div className="generator-page">
      <header>
        <h1>Makefile Generator</h1>
      </header>

      <section className="generator-form">
        <p>Enter your configurations below to generate a Makefile:</p>
        <form>
          {/* Form inputs for generator options */}
        </form>
      </section>
    </div>
  );
}

export default GeneratorPage;
