import React from 'react';
import './ContactPage.css'; // Import your custom CSS for styling

const ContactPage: React.FC = () => {
  return (
    <div className="contact-page">
      <header>
        <h1>Contact Us</h1>
      </header>

      <section className="contact-form">
        <form>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message"></textarea>

          <button type="submit">Submit</button>
        </form>
      </section>
    </div>
  );
}

export default ContactPage;
