// Cmatrix.tsx
import React, { useEffect, useRef } from "react";
import { generateAsciiArtBanner } from "./cmatrix_essentials";
import "./cmatrix.css";

interface CmatrixProps {
  animationSpeed: number;
}

const Cmatrix: React.FC<CmatrixProps> = ({ animationSpeed }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext("2d")!;
    const fontSize = 16;
    let columns = Math.floor(window.innerWidth / fontSize); // Initial column count based on window width
    const drops: number[] = Array.from({ length: columns }, () => 1);

    const draw = () => {
      context.fillStyle = "rgba(0, 0, 0, 0.05)";
      context.fillRect(0, 0, canvas.width, canvas.height);

      context.fillStyle = "#0F0";
      context.font = fontSize + "px monospace";

      const randomEffect = Math.floor(Math.random() * 3);

      for (let i = 0; i < drops.length; i++) {
        let text = "";

        switch (randomEffect) {
          case 0:
            text = String.fromCharCode(Math.floor(Math.random() * 94) + 33);
            break;
          case 1:
            const asciiArt = generateAsciiArtBanner();
            text = asciiArt.charAt(i % asciiArt.length);
            break;
          case 2:
            text = String.fromCharCode(Math.floor(Math.random() * 94) + 33);
            break;
          default:
            break;
        }

        context.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i]++;
      }
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      columns = Math.floor(canvas.width / fontSize);
    };

    handleResize(); // Initial resize

    window.addEventListener("resize", handleResize);

    const intervalId = setInterval(draw, 30);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleResize);
    };
  }, [animationSpeed]);

  return (
    <div>
      <canvas ref={canvasRef} className="cmatrix-background" />
    </div>
  );
};

export default Cmatrix;
