import React from 'react';
import './NewsPage.css'; // Import your custom CSS for styling

const NewsPage: React.FC = () => {
  return (
    <div className="news-page">
      <header>
        <h1>Latest News</h1>
      </header>

      <section className="news-list">
        <article>
          <h2>Exciting Announcement</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec turpis ac turpis cursus feugiat et at enim.</p>
          <span>Published on: August 12, 2024</span>
        </article>

        <article>
          <h2>New Feature Release</h2>
          <p>Curabitur hendrerit lacus at neque efficitur, a commodo orci ultrices. Vivamus sit amet elit eget quam tincidunt imperdiet a vitae justo.</p>
          <span>Published on: August 10, 2024</span>
        </article>

        {/* Additional news articles go here */}
      </section>
    </div>
  );
}

export default NewsPage;
