import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import your Navbar component
import HomePage from './components/HomePage';
import GeneratorPage from './components/GeneratorPage';
import NewsPage from './components/NewsPage';
import ContactPage from './components/ContactPage';
import './App.css';

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/generator" Component={GeneratorPage} />
      <Route path="/news" Component={NewsPage} />
      <Route path="/contact" Component={ContactPage} />
      </Routes>
      {/* Create footer enigmasec and majster247 2022-2024*/}
      <footer>
        <p>Enigmasec and Majster247 2022-2024</p>
      </footer>
      </div>
  );
}

export default App;
