import React from 'react';
import './Navbar.css'; // Import your custom CSS for styling
import { Link } from 'react-router-dom'; // Import the Link component from React Router

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <h1>LFS Generator</h1>
      </div>

      <div className="nav-links">
      <nav>
          <Link to="/">Home</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/cert">Certificates</Link>
          <Link to="/portfolio">Portfolio</Link>
        </nav>
      </div>
    </nav>
  );
}

export default Navbar;
