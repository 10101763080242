// HomePage.tsx

import React, { useEffect, useState } from 'react';
import './homepage.css';
import Cmatrix from './cmatrix/cmatrix'; // Assuming the file is named Cmatrix.tsx

const HomePage: React.FC = () => {
  const [isSiteLoaded, setIsSiteLoaded] = useState(false);

  useEffect(() => {
    // Simulate site load delay for demonstration purposes
    const siteLoadDelay = setTimeout(() => {
      setIsSiteLoaded(true);
    }, 2000);

    // Clean up the timeout when the component is unmounted
    return () => clearTimeout(siteLoadDelay);
  }, []);

  return (
    <div className="homepage-container">
      <div className='left-container'>
        {isSiteLoaded && <Cmatrix animationSpeed={10} />}
      </div>
      <div className='right-container'>
        <header>
          <h1>Welcome to our LFS Project</h1>
          <p>Generate your own LFS for your purpose</p>
        </header>

        <section className="overview-section">
          <h2>Project Overview</h2>
          <p>
            LFS generator is a tool that allows you to create your own Linux From Scratch (LFS) distribution. It provides a simple and intuitive interface to help you build your custom Linux distribution from the ground up.
          </p>
          {/* Module for cmatrix */}
        </section>

        <section className="makefile-section">
          <h2>Makefile Generation</h2>
          <p>
            Generate Makefiles effortlessly with our powerful tools. Simplify the build process and manage your project efficiently.
          </p>
          {/* Additional content specific to Makefile generation */}
        </section>
      </div>
    </div>
  );
}

export default HomePage;
